/**
* Implement Gatsby's Browser APIs in this file.
*
* See: https://www.gatsbyjs.org/docs/browser-apis/
*/



// -----------------
// Override the default scroll position on route change, which fires window.scrollTo(0, 0)

exports.shouldUpdateScroll = () => false
